import React  from 'react';
import "../styles/Footer.css";
import {upcoming} from "../utils/data"


const Footer = () => {

    return (
    <div id="footer">
        <div className="footer-container">
            <h1 style={{fontFamily:'Inter-Light'}}>
                Upcoming <strong>features</strong>
            </h1>
            <div className="upcoming-container">
            {Object.keys(upcoming).map((key, index) => (
                <div key={index} >
                    <div className="upcoming-data">
                        <h2>
                            {upcoming[key]["title"]}
                        </h2>
                        <button className={upcoming[key]["status"] === "Released" ? "status-green" : "status-yellow"}>
                        {upcoming[key]["status"]}
                        </button>
                        <p style={{textAlign: 'left'}}>{upcoming[key]["desc"]}</p>
                    </div>
                </div>
            ))}
            </div>

        <div id="roadmap">
            <img src={ '/assets/images/icons/footer/navigation.png'}  
                alt="doc Etherscore" 
                width={18}
                height={18}
                style={{filter:'invert(100%)'}}
            />
            {/*  eslint-disable-next-line */}
            <a href='https://docs.etherscore.network/product/roadmap' target="_blank" rel="noreferrer">See full roadmap</a>
        </div>
   
        <h1 style={{marginTop: '1.5em', fontFamily:'Inter-Light'}}>
            It's all about<strong><br />you</strong>
        </h1>
        <p style={{fontSize:'15px', marginTop:'3em'}}>Take a peek! EtherScore is extremely simple to use.<br/>We’re sure your first badges are waiting to be claimed.</p>
        </div>
        <a href="https://alpha.etherscore.network/" target="_blank" rel="noreferrer">
            <button id='unlock-btn'>Unlock your achievements</button>
        </a> 
        <div className="footer-basic-elements">
            <div className="footer-left-elements">
                <div className="icons">
                    <div id='etherscore-logo'>
                    {/*  eslint-disable-next-line */}
                    <a href='#'>
                        <img src={ '/assets/images/logo-etherScore.svg'}  
                            alt="logo Etherscore" 
                            width={150}
                            height={18}
                        />
                    </a>
                    </div>
                    <div id='discord-icon'>
                    {/*  eslint-disable-next-line */}
                    <a href='https://discord.com/invite/6yrKCgEgzp' target="_blank" rel="noreferrer">
                        <img src={ '/assets/images/icons/legals/discord-icon.png'}  
                            alt="discord icon" 
                            width={35}
                            height={35}
                        />
                    </a>
                    </div>
                    <div id='twitter-icon'>
                    {/*  eslint-disable-next-line */}
                    <a href='https://twitter.com/EtherScore' target="_blank" rel="noreferrer">
                        <img src={ '/assets/images/icons/legals/twitter-icon.png'}  
                            alt="twitter icon" 
                            width={35}
                            height={35}
                        />
                    </a>
                    </div>
                    <div id='medium-icon'>
                    {/*  eslint-disable-next-line */}
                    <a href='https://medium.com/@etherscore' target="_blank" rel="noreferrer">
                        <img src={ '/assets/images/icons/legals/medium-icon.png'}  
                            alt="medium icon" 
                            width={35}
                            height={35}
                        />
                    </a>
                    </div>
                    <div id='lens-icon'>
                    {/*  eslint-disable-next-line */}
                    <a href='https://lenster.xyz/u/etherscore' target="_blank" rel="noreferrer">
                        <img src={ '/assets/images/icons/legals/lens-icon.png'}  
                            alt="lens icon" 
                            width={35}
                            height={35}
                            style={{filter:'brightness(100)'}}
                        />
                    </a>
                    </div>
                </div>
                <div className="legals">
                    <p>© 2023 All rights reserved.</p>
                    <a href="mailto:contact@onchainedlab.com" style={{textDecoration: 'none'}}>
                        <p>contact@onchainedlab.com</p>
                    </a>
                </div>
            </div>
        </div>
    </div>
    );
}

export default Footer;


//Remplaces la div className "legals" par celle ci. Elle contient en +, le lien vers le pdf cgu-cgv (qu'on ne voulait pas display pour le moment).

/*
<div className="legals">
    <p>© 2023 All rights reserved.</p>
    <Link to="/legals/cgu-cgv.pdf" target="_blank" style={{textDecoration: 'none'}}>
    <p style={{textDecoration: 'underline', textUnderlineOffset:'3px'}}>Privacy Policy</p>
    </Link>
    <a href="mailto:contact@onchainedlab.com" style={{textDecoration: 'none'}}>
        <p>contact@onchainedlab.com</p>
    </a>
</div>
*/