import React from 'react';
import "../styles/Intro.css";

export default function Intro() {

  
    return (
      <div id="intro">
          <div className="intro-container">
              <video className="resize-video" autoPlay muted>
                <source src={require('../animations/video/Header-cartes.webm')} type="video/webm" />
              </video>
            <div className="intro-title">
              <h1>Build <span>Reputation</span>, Start <span>Shining</span><br/>& Get <span>Rewarded</span></h1>
            </div>
            <div className="intro-description">
                <p>
                Unlock badges from your favorite apps to grow your reputation 
                <br/>
                and get access to exclusive rewards.
                </p>
            </div>
            <a href="https://alpha.etherscore.network/" target="_blank" rel="noreferrer">
              <button id='start-app'><span>Get started</span></button>
            </a> 
            <div id="documentation">
                <img src={ '/assets/images/icons/Icon-Documentation.svg'}  
                    alt="doc Etherscore" 
                    width={18}
                    height={18}
                />
                {/*  eslint-disable-next-line */}
                <a href='https://docs.etherscore.network/' target="_blank" rel="noreferrer">Learn more</a>
            </div> 
          </div>
      </div>
    );
}